/*
 * @Author: v-fmasoud@tableau.com
 * @Date: 2017-10-10 11:23:11
 * @Last Modified by: fmasoud@tableau.com
 * @Last Modified time: 2018-06-28 13:42:11
 */

import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import XHR from 'i18next-xhr-backend';
import Backend from 'i18next-chained-backend';
import LocalStorageBackend from 'i18next-localstorage-backend';

i18n
  .use(LanguageDetector)
  .use(XHR)
  // Stores translation files in local storage.
  // Options below to contorl caching behavior.
  .use(Backend)
  .init({
    debug: false,
    nsSeparator: false,
    keySeparator: false,
    fallbackLng: "en-US",
    returnEmptyString: false, // Consider empty string values as invalid translations.
    returnNull: false,
    lowerCaseLng: false,
    // Disable trying to load locale specific files since we only have the base languages.
    // If in the future there are locale specific files, remove this option.
    backend: {
      backends: [
        LocalStorageBackend, // primary
        XHR, // fallback
      ],
      backendOptions: [
        {
          // prefix for stored languages
          prefix: 'i18next_res_',
          expirationTime: 365 * 24 * 60 * 60 * 1000, // 1 year
          versions: {
            'de-DE': 'v3.2',
            'en-US': 'v3.2',
            'en-GB': 'v3.2',
            'fr-FR': 'v3.2',
            'fr-CA': 'v3.2',
            'ja-JP': 'v3.2',
            'zh-CN': 'v3.2',
            'zh-TW': 'v3.2',
            'sv-SE': 'v3.2',
            'th-th': 'v3.2',
            'ko-KR': 'v3.2',
            'pt-BR': 'v3.2',
            'es-ES': 'v3.2',
            'it-IT': 'v3.2',
            'nl-NL': 'v3.2',
          },
        },
        {
          loadPath: `${process.env.PUBLIC_URL}/locales/{{lng}}/{{ns}}.json`, // xhr load path for my own fallback
          addPath: null,
          saveMissing: false,
        },
      ],
    },
    ns: ['translation'],
    defaultNS: 'translation',
    interpolation: {
      escapeValue: false, // not needed for react!!
    },
    react: {
      wait: true,
    },
    detection: {
      // Settings for language detector
      order: ['querystring', 'cookie', 'navigator'],
      lookupQuerystring: 'lng',
      lookupCookie: 'tableauLang',
      caches: ['cookie'],
      cookieMinutes: 525600, // one year
      cookieDomain: window.REACT_APP_LANGUAGE_COOKIE_DOMAIN,
    },
    saveMissing: false,
  });

export default i18n;
